import firebase from 'firebase/app'

import React, { FunctionComponent } from 'react'

import { Router, WindowLocation } from '@reach/router'

import DefaultLayout from '../layouts/index'

import BillingOverview from '../components/billing-overview'
import BillingHistory from '../components/billing-history'
import ManageSubscriptions from '../components/manage-subscriptions'
import ManagePayments from '../components/manage-payments'
import FirebaseLoadable from '../components/firebase-loadable'
import PrivateRoute from '../components/private-route'

type BillingProps = {
  location: WindowLocation
}

const Billing: FunctionComponent<BillingProps> = ({ location }) => {
  let resolver
  let rejecter
  const firebasePromise = new Promise<firebase.FirebaseApp>(
    (resolve, reject) => {
      resolver = resolve
      rejecter = reject
    },
  )
  const [firebaseApp, setFirebaseApp] = React.useState<
    firebase.FirebaseApp | undefined
  >(undefined)

  firebasePromise
    .then((fbApp: firebase.FirebaseApp) => {
      setFirebaseApp(fbApp)
    })
    .catch((reason) => {
      console.error(reason)
    })

  return (
    <DefaultLayout location={location} firebase={firebaseApp}>
      <FirebaseLoadable resolver={resolver} rejecter={rejecter} />
      {!firebaseApp ? (
        <div>Initializing Firebase...</div>
      ) : (
        <Router basepath="/billing">
          <PrivateRoute
            location={location}
            path="/"
            firebase={firebaseApp}
            component={BillingOverview}
          />
          <PrivateRoute
            location={location}
            path="history"
            firebase={firebaseApp}
            component={BillingHistory}
          />
          <PrivateRoute
            location={location}
            path="payments"
            firebase={firebaseApp}
            component={ManagePayments}
          />
          <PrivateRoute
            location={location}
            path="subscription"
            firebase={firebaseApp}
            component={ManageSubscriptions}
          />
        </Router>
      )}
    </DefaultLayout>
  )
}

export default Billing

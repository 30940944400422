import { SiteConfig } from './types'
/* eslint-disable @typescript-eslint/no-var-requires */
const { siteMetadata }: SiteConfig =
  require('../../gatsby-config.ts') as SiteConfig
/* eslint-enable @typescript-eslint/no-var-requires */

export enum Environment {
  local = 'local',
  staging = 'staging',
  production = 'production',
}

type FlagSpec = {
  _test_flag: boolean
  enableBillingHistory: boolean
  enableDropboxSource: boolean
  enableDropboxSourceSubfolder: boolean
  enableSubscriptionManagement: boolean
}

const flags: { [key in Environment]: FlagSpec } = {
  [Environment.local]: {
    _test_flag: true,
    enableBillingHistory: false,
    enableDropboxSource: true,
    enableDropboxSourceSubfolder: false,
    enableSubscriptionManagement: true,
  },
  [Environment.staging]: {
    _test_flag: true,
    enableBillingHistory: false,
    enableDropboxSource: true,
    enableDropboxSourceSubfolder: false,
    enableSubscriptionManagement: true,
  },
  [Environment.production]: {
    _test_flag: true,
    enableBillingHistory: false,
    enableDropboxSource: true,
    enableDropboxSourceSubfolder: false,
    enableSubscriptionManagement: true,
  },
}

export const getFlags = (): FlagSpec => {
  const env_val = siteMetadata.config.environment
  if (typeof env_val === 'string' && env_val in Environment) {
    return flags[env_val as Environment]
  } else {
    throw new Error(
      `Environment configuration ${env_val} was invalid when evaluating ` +
        'active config flags',
    )
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const test_compiles_lookup_flag_spec: FlagSpec = flags[Environment.local]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const test_compiles_get_flag: boolean = flags[Environment.local]._test_flag
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const test_compiles_lookup_runtime_selected_flag_spec: FlagSpec = getFlags()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const test_compiles_get_runtime_selected_flag: boolean = getFlags()._test_flag

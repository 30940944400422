import React, { FunctionComponent } from 'react'

import firebase from 'firebase/app'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { loadStripe, Stripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import StripeCardForm, {
  FormChangedEvent,
  FormValues as StripeCardFormValues,
} from './stripe/stripe-card-form'

import * as api from '../utils/api'
import * as types from '../utils/types'

import { SiteConfig } from '../utils/types'
/* eslint-disable @typescript-eslint/no-var-requires */
const { siteMetadata }: SiteConfig =
  require('../../gatsby-config.ts') as SiteConfig
/* eslint-enable @typescript-eslint/no-var-requires */

type UpdateCreditCardFormProps = {
  firebase: firebase.FirebaseApp
}

const UpdateCreditCardFormController = ({
  firebase: firebaseApp,
}: UpdateCreditCardFormProps) => {
  // TODO(alex): Validate that this count has a configured subscription before
  // allowing update of payment info.
  const [componentLoaded, setComponentLoaded] = React.useState(false)

  // Just get stripePromise once
  const [stripePromise, setStripePromise] =
    React.useState<Promise<Stripe | null> | null>(null)
  React.useEffect(() => {
    setStripePromise(loadStripe(siteMetadata.config.stripeConfig.apiKey))
  }, [])

  React.useEffect(() => {
    if (stripePromise != undefined) {
      setComponentLoaded(true)
    }
    return () => undefined
  }, [stripePromise])

  const [cardInfo, setCardInfo] = React.useState<StripeCardFormValues | null>(
    null,
  )
  const [cardInfoFormIsValidAndComplete, setCardInfoFormIsValidAndComplete] =
    React.useState(false)
  const [currentlyUpdatingCardInfo, setCurrentlyUpdatingCardInfo] =
    React.useState(false)
  // TODO(alex): Replace various exit state variables with an enum of some form
  const [cardHasBeenUpdatedSuccessfully, setCardHasBeenUpdatedSuccessfully] =
    React.useState(false)
  const [cardUpdateFailed, setCardUpdateFailed] = React.useState(false)

  const submitUpdatedCard = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      const submitter = async () => {
        setCurrentlyUpdatingCardInfo(true)

        if (cardInfo === null) {
          console.error('Card Info was null on submit')
          return
        }

        const stripeResult = await cardInfo.stripe.createPaymentMethod({
          type: 'card',
          card: cardInfo.cardElement,
          billing_details: {
            name: cardInfo.name,
          },
        })
        if (stripeResult.error) {
          console.error(
            'Stripe payment method creation failed: ',
            stripeResult.error,
          )
          return
        }

        const { id: paymentMethodId } = stripeResult.paymentMethod

        let accountId
        try {
          accountId = await api.getAccountId(firebaseApp)
        } catch (error) {
          console.error('Account retrieval failed:', error)
          return
        }

        if (accountId === types.None) {
          console.error('No account found for this patron.')
          return
        }

        try {
          await api.updateSubscriptionPaymentMethod(
            firebaseApp,
            accountId,
            paymentMethodId,
          )
        } catch (error) {
          // TODO(alex): Communicate this failure to the patron
          console.error(
            'Updating subscription payment method failed with error:',
            error,
          )
          setCardUpdateFailed(true)
          setCurrentlyUpdatingCardInfo(false)
        }

        setCardHasBeenUpdatedSuccessfully(true)
        setCurrentlyUpdatingCardInfo(false)
      }

      void submitter()
      event.preventDefault()
      return false
    },
    [cardInfo, firebaseApp],
  )

  const updateCardInfoFromStripeForm = React.useCallback(
    (event: FormChangedEvent) => {
      setCardInfoFormIsValidAndComplete(event.complete)
      if (event.complete) {
        setCardInfo({
          name: event.values.name,
          cardElement: event.values.cardElement,
          stripe: event.values.stripe,
        })
      }
    },
    [setCardInfoFormIsValidAndComplete],
  )

  return {
    state: {
      componentLoaded,
      stripePromise,
      cardUpdateFailed,
      cardHasBeenUpdatedSuccessfully,
      currentlyUpdatingCardInfo,
      cardInfoFormIsValidAndComplete,
    },
    model: {
      cardInfo,
    },
    actions: {
      submitUpdatedCard,
      updateCardInfoFromStripeForm,
    },
  }
}

const UpdateCreditCardForm: FunctionComponent<UpdateCreditCardFormProps> = (
  props,
) => {
  const c = UpdateCreditCardFormController(props)

  const cssClasses = makeStyles({
    updateCardForm: {
      width: '100%',
    },
  })()

  return (
    <>
      {!c.state.componentLoaded || c.state.stripePromise === null ? (
        <CircularProgress />
      ) : (
        <>
          {c.state.cardHasBeenUpdatedSuccessfully ||
          c.state.cardUpdateFailed ? (
            <>
              {c.state.cardHasBeenUpdatedSuccessfully ? (
                <Typography>
                  Card info has successfully been updated.
                </Typography>
              ) : (
                // TODO(alex): Communicate _why_ to the patron.
                <Typography>Card update failed.</Typography>
              )}
            </>
          ) : (
            <form
              className={cssClasses.updateCardForm}
              onSubmit={c.actions.submitUpdatedCard}>
              <Elements stripe={c.state.stripePromise}>
                <StripeCardForm
                  title="New Card Info"
                  onFormChanged={c.actions.updateCardInfoFromStripeForm}
                />
              </Elements>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6} />
                <Grid item xs={12} md={6}>
                  {c.state.currentlyUpdatingCardInfo ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={true}
                      fullWidth>
                      <CircularProgress size={24} />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={!c.state.cardInfoFormIsValidAndComplete}
                      type="submit">
                      Update Card Information
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
    </>
  )
}

export default UpdateCreditCardForm

import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import firebase from 'firebase/app'
import { getAuth, User as FirebaseUser } from 'firebase/auth'

import { navigate } from 'gatsby'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import * as api from '../utils/api'
import * as types from '../utils/types'

import UpdateCreditCardForm from '../components/update-credit-card-form'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
)

type ManagePaymentsProps = {
  firebase: firebase.FirebaseApp
}

const ManagePayments: FunctionComponent<ManagePaymentsProps> = ({
  firebase: firebaseApp,
}) => {
  const [componentLoaded, setComponentLoaded] = React.useState(false)

  React.useEffect(() => {
    const asyncEffect = async (currentUser: FirebaseUser) => {
      if (!currentUser) {
        console.warn('No user authenticated')
        return
      }

      let accountId
      try {
        // TODO(alex): Handle multiple accounts.
        accountId = await api.getAccountId(firebaseApp)
      } catch (error) {
        console.error('An error occurred while getting account ID', error)
        await navigate('/sign-up')
        return
      }

      if (!accountId || accountId === types.None) {
        await navigate('/sign-up')
        return
      }

      setComponentLoaded(true)
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged((user) => {
      if (!user) {
        console.warn('No user authenticated')
        return
      }
      void asyncEffect(user)
    })

    return () => {
      unsubscribe()
    }
  }, [firebaseApp])

  const classes = useStyles()

  return (
    <>
      <Helmet
        title={'Payment Information'}
        meta={[
          {
            name: 'description',
            content: 'Manage payment information.',
          },
          {
            name: 'keywords',
            content: 'website, hosting, billing, dashboard',
          },
        ]}
      />
      {!componentLoaded ? (
        <CircularProgress />
      ) : (
        <div>
          <h1>Manage Payments</h1>
          <Accordion>
            <AccordionSummary
              aria-controls="credit-card-update-form-content"
              id="credit-card-update-form-header">
              <Typography className={classes.heading}>
                Update Credit Card Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UpdateCreditCardForm firebase={firebaseApp} />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  )
}

export default ManagePayments

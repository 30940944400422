import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import firebase from 'firebase/app'
import { getAuth, User as FirebaseUser } from 'firebase/auth'

import { navigate } from 'gatsby'

import CircularProgress from '@material-ui/core/CircularProgress'

import * as api from '../utils/api'
import * as types from '../utils/types'

type BillingHistoryProps = {
  firebase: firebase.FirebaseApp
}

const BillingHistory: FunctionComponent<BillingHistoryProps> = ({
  firebase: firebaseApp,
}) => {
  const [componentLoaded, setComponentLoaded] = React.useState(false)

  React.useEffect(() => {
    const asyncEffect = async (currentUser: FirebaseUser) => {
      if (!currentUser) {
        console.warn('No user authenticated')
        return
      }

      let accountId
      try {
        // TODO(alex): Handle multiple accounts.
        accountId = await api.getAccountId(firebaseApp)
      } catch (error) {
        console.error('An error occurred while getting account ID', error)
        await navigate('/sign-up')
        return
      }

      if (!accountId || accountId === types.None) {
        await navigate('/sign-up')
        return
      }

      setComponentLoaded(true)
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged((user) => {
      if (!user) {
        console.warn('No user authenticated')
        return
      }
      void asyncEffect(user)
    })

    return () => {
      unsubscribe()
    }
  }, [firebaseApp])

  return (
    <>
      <Helmet
        title={'Billing History'}
        meta={[
          {
            name: 'description',
            content: 'Manage billing information.',
          },
          {
            name: 'keywords',
            content: 'website, hosting, billing, dashboard',
          },
        ]}
      />
      {!componentLoaded ? (
        <CircularProgress />
      ) : (
        <div>
          <h1>{'Billing History'}</h1>
        </div>
      )}
    </>
  )
}

export default BillingHistory

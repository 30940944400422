import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import firebase from 'firebase/app'
import { getAuth, User as FirebaseUser } from 'firebase/auth'

import { navigate } from 'gatsby'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import * as api from '../utils/api'
import * as types from '../utils/types'
import { SubscriptionStatus } from '../utils/types'

type ManageSubscriptionsProps = {
  firebase: firebase.FirebaseApp
}

const ManageSubscriptionsController = ({
  firebase: firebaseApp,
}: ManageSubscriptionsProps) => {
  // State
  const [componentLoaded, setComponentLoaded] = React.useState(false)
  const [currentlyUnsubscribing, setCurrentlyUnsubscribing] =
    React.useState(false)

  // Model
  const [subscriptionAccountId, setSubscriptionAccountId] = React.useState<
    string | null
  >(null)
  const [subscriptionStatus, setSubscriptionStatus] = React.useState(
    SubscriptionStatus.Unknown,
  )

  React.useEffect(() => {
    const asyncEffect = async (currentUser: FirebaseUser) => {
      if (!currentUser) {
        console.warn('No user authenticated')
        return
      }

      let retrievedAccountId: types.Option<string>
      try {
        retrievedAccountId = await api.getAccountId(firebaseApp)
      } catch (error) {
        console.error('Account retrieval failed:', error)
        // Redirect to account creation flow
        await navigate('/sign-up')
        return
      }
      if (retrievedAccountId === types.None) {
        console.error('No account found for this patron.')
        await navigate('/sign-up')
        return
      }
      setSubscriptionAccountId(retrievedAccountId)

      try {
        if (!retrievedAccountId) {
          console.error('Account ID is null unexpectedly.')
          return
        }
        const subscriptionStatus = await api.getSubscriptionStatus(
          firebaseApp,
          retrievedAccountId,
        )

        // TODO(alex): Actually get subscription status info from backend
        setSubscriptionStatus(subscriptionStatus)
      } catch (error) {
        console.error(
          'Getting subscription status failed for some reason: ',
          error,
        )
      }

      setComponentLoaded(true)
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged((user) => {
      if (!user) {
        console.warn('No user authenticated')
        return
      }
      void asyncEffect(user)
    })

    return () => {
      unsubscribe()
    }
  }, [firebaseApp])

  // Actions
  const unsubscribe = React.useCallback(async () => {
    if (!subscriptionAccountId) {
      console.error('Cannot unsubscribe with nullish account ID')
      return
    }

    setCurrentlyUnsubscribing(true)
    // TODO(alex): Actually pay attention to potential errors here instead of
    // just exploding on failure and/or ignoring useful information.
    await api.cancelSubscription(firebaseApp, subscriptionAccountId)
    setSubscriptionStatus(SubscriptionStatus.ActivePendingCancellation)
    setCurrentlyUnsubscribing(false)
  }, [firebaseApp, subscriptionAccountId])

  return {
    state: {
      componentLoaded,
      currentlyUnsubscribing,
    },

    model: {
      subscriptionStatus,
    },

    actions: {
      unsubscribe,
    },
  }
}

const ManageSubscriptions: FunctionComponent<ManageSubscriptionsProps> = (
  props,
) => {
  const c = ManageSubscriptionsController(props)
  return (
    <>
      <Helmet
        title={'Subscription Information'}
        meta={[
          {
            name: 'description',
            content: 'Manage subscription information.',
          },
          {
            name: 'keywords',
            content: 'website, hosting, billing, dashboard',
          },
        ]}
      />
      {!c.state.componentLoaded ? (
        <CircularProgress />
      ) : (
        <>
          <div>
            <h1>{'Manage Subscriptions'}</h1>
          </div>

          <Grid container spacing={1}>
            {c.model.subscriptionStatus === SubscriptionStatus.Active ||
            c.model.subscriptionStatus === SubscriptionStatus.New ? (
              <Grid item xs={12}>
                <UnsubscribeButton
                  currentlyUnsubscribing={c.state.currentlyUnsubscribing}
                  disabled={
                    c.model.subscriptionStatus !== SubscriptionStatus.Active &&
                    c.model.subscriptionStatus !== SubscriptionStatus.New
                  }
                  onClick={c.actions.unsubscribe}
                />
              </Grid>
            ) : (
              <>
                {c.model.subscriptionStatus ===
                SubscriptionStatus.ActivePendingCancellation ? (
                  <Grid item xs={12}>
                    <Typography>
                      This subscription is{' '}
                      <b>
                        currently <i>pending cancellation</i>
                      </b>
                      . A final bill will be invoiced at the end of this pay
                      period, and the associated project will stop serving
                      traffic unless your subscription is restarted.
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    {c.model.subscriptionStatus ===
                    SubscriptionStatus.RenewalCancelled ? (
                      <Grid item xs={12}>
                        <Typography>
                          <b>
                            Renewal <i>has been cancelled</i>
                          </b>
                          . A final bill will be invoiced at the end of this pay
                          period, and the associated project will stop serving
                          traffic unless your subscription is restarted.
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Typography>
                          This subscription{' '}
                          <b>
                            is <i>currently inactive</i>
                          </b>
                          . To resume serving traffic for the associated
                          project, restart your subscription.
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}

                {/*
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={true}
                    fullWidth>
                    Restart Subscription
                  </Button>
                </Grid>
              */}
                <Grid item xs={12}>
                  <Typography>
                    To <i>restart your subscription</i>, please email{' '}
                    <a
                      style={{ color: '#0E62A1' }}
                      href="mailto:subscriptions@hostburro.com">
                      subscriptions@hostburro.com
                    </a>{' '}
                    from the address used to log into <b>this account</b>.
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  )
}

type UnsubscribeButtonProps = {
  currentlyUnsubscribing: boolean
  disabled: boolean
  onClick: React.MouseEventHandler
}
const UnsubscribeButton: FunctionComponent<UnsubscribeButtonProps> = ({
  currentlyUnsubscribing,
  disabled,
  onClick,
}) => {
  return (
    <Tooltip title="Stop your subscription at the end of this pay period">
      <span>
        {currentlyUnsubscribing ? (
          <Button variant="contained" color="primary" disabled={true} fullWidth>
            <CircularProgress size={24} /> &nbsp; Cancelling Renewal
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            fullWidth
            onClick={onClick}>
            Stop Subscription Renewal
          </Button>
        )}
      </span>
    </Tooltip>
  )
}

export default ManageSubscriptions
